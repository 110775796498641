import React from "react"
import { SEO } from "../components/Seo"
import { rhythm, scale } from "../utils/typography"
import { GameCodebaseToursHeader } from "../components/Header"
import { EmailSignup } from "../components/EmailSignup"
import { DiabeticRed } from "../utils/colors"
import theme from "../utils/theme"
import { useMediaQuery } from "react-responsive"
import { Footer } from "../components/Footer"
import { graphql } from "gatsby"
import Image from "gatsby-image"
// import { Link, graphql } from "gatsby"
// import PreviewImage from '../components/PreviewImage'
// import ColorHash from 'color-hash'

const BodyFontFamily = theme.bodyFontFamily
  .map((font, i) =>
    i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { ne: "portfolio" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tags
          }
          fields {
            slug
          }
        }
      }
    }
    tangela: file(relativePath: { eq: "tangela.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mockup: file(relativePath: { eq: "mockup6.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    placeholder: file(relativePath: { eq: "600x400.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    codebase: file(relativePath: { eq: "codebase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    guideExample: file(relativePath: { eq: "guideexample.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cardPileSystem: file(relativePath: { eq: "cardpilesystem2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gameCodebaseTour: file(relativePath: { eq: "gamecodebasetour.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const GRAY_BACKGROUND = "rgba(243,247,249,1)"
const AVATAR_BACKGROUND = "#ddd"

export default function Page({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const Heading = props => {
    if (!props.is) {
      throw new Error('"is" property is required.')
    }

    const style = {
      marginTop: 0,
      paddingTop: rhythm(3),
      textAlign: props.textAlign || "center",
      fontFamily: BodyFontFamily,
      ...(isMobile ? { ...scale(1 / 2), lineHeight: rhythm(4 / 3) } : {}),
    }

    if (props.is === "h1") {
      return <h1 style={style}>{props.children}</h1>
    }

    if (props.is === "h2") {
      return <h2 style={style}>{props.children}</h2>
    }

    if (props.is === "h3") {
      return <h3 style={style}>{props.children}</h3>
    }

    if (props.is === "h4") {
      return <h4 style={style}>{props.children}</h4>
    }
  }

  const Section = props => {
    return (
      <section
        style={{
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: rhythm(1 / 2),
          paddingRight: rhythm(1 / 2),
        }}
      >
        {props.children}
      </section>
    )
  }

  const HighlightedBackground = props => (
    <div style={{ backgroundColor: GRAY_BACKGROUND }}>{props.children}</div>
  )

  const Subheader = props => {
    return (
      <h2
        style={{
          textAlign: "center",
          fontFamily: BodyFontFamily,
          fontWeight: 100,
          marginTop: 0,
          ...(isMobile ? scale(1 / 3) : {}),
          ...(props.style || {}),
        }}
      >
        {props.children}
      </h2>
    )
  }

  return (
    <>
      <SEO
        description="Learn how to make your favorite game genre by studying source code walkthroughs of finished game projects."
        imageUrl="https://user-images.githubusercontent.com/914228/153866416-316b3343-757e-4039-bbba-da7ec7973c9e.png"
        imageAlt="Learn how to make your favorite game genre by studying source code walkthroughs of finished game projects."
        title="Game Codebase Tours"
      />
      <GameCodebaseToursHeader
        styleProps={{ backgroundColor: GRAY_BACKGROUND }}
        avatarStyleProps={{ backgroundColor: AVATAR_BACKGROUND }}
      />
      <main>
        <HighlightedBackground>
          <Section>
            <Heading is="h1">
              Fully explained game codebases in Unity. Learn how to make games{" "}
              <span style={{ color: DiabeticRed }}>by example</span>.
            </Heading>
            <Subheader>
              Learn how to make your favorite game genre by studying source code
              walkthroughs of finished game projects.
            </Subheader>
          </Section>
          <div
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: rhythm(1 / 2),
              paddingRight: rhythm(1 / 2),
              marginBottom: rhythm(1),
            }}
          >
            <Image fluid={data.mockup.childImageSharp.fluid} alt="mockup" />
          </div>
          {/*
          <Section>
            <iframe
              title="call to action 1"
              frameBorder="0"
              src="https://itch.io/embed/1111372"
              width="100%"
              height="167"
            >
              <a href="https://nucleartide.itch.io/pocket-pong-ar">
                Pocket Pong AR by nucleartide
              </a>
            </iframe>
          </Section>
          */}
          <Section>
            <EmailSignup
              altColor
              urlPath="/game-codebase-tours"
              copy={
                <>
                  <span>
                    Sign up to be notified when a new{" "}
                    <em>Game Codebase Tour</em> is launched:
                  </span>
                </>
              }
              style={{
                paddingTop: 0,
                paddingBottom: rhythm(1),
              }}
            />
            <Subheader
              style={{
                marginBottom: 0,
                paddingBottom: rhythm(1),
                fontStyle: "italic",
              }}
            >
              Or take a look below ↓
            </Subheader>
          </Section>
        </HighlightedBackground>
        <Section>
          <Heading is="h1">
            <span>So you have a cool game idea...</span>
            <span role="img" aria-label="light bulb">
              💡
            </span>
          </Heading>
          <p>
            But when it comes time to <em>make</em> your idea, you run into all
            sorts of roadblocks:
          </p>
          <ul>
            <li>
              <strong>Scope.</strong> You're unsure how to scope your idea down
              into something manageable.
            </li>
            <li>
              <strong>Work breakdown.</strong> You're unsure how to break your
              idea into bite-sized development tasks.
            </li>
            <li>
              <strong>Progress.</strong> Your codebase becomes tangled as you
              add features, so you struggle to make progress.
            </li>
          </ul>
          <p>
            The result? You fail to <strong>ship</strong> your game project –
            one that you're <em>actually</em> proud to show to your friends.
          </p>
          <div>
            <div
              style={{ maxWidth: 250, marginLeft: "auto", marginRight: "auto" }}
            >
              <Image fluid={data.tangela.childImageSharp.fluid} alt="Tangela" />
            </div>
            <p className="mario-caption">
              <small>
                Tangela is adorable, but not when it's your codebase!
              </small>
            </p>
          </div>
        </Section>
        <Section>
          <Heading is="h2">
            A game developer can dream...{" "}
            <span role="img" aria-label="thought cloud">
              💭
            </span>
          </Heading>
          <p>
            But what if you knew <em>exactly</em> how to scope your idea down,
            while keeping the spirit of your original idea?
          </p>
          <p>
            What if you knew <em>exactly</em> how to break your idea into
            bite-sized tasks that you can start tackling today?
          </p>
          <p>
            And what if adding features to your game was a <strong>joy</strong>,
            not a chore?
          </p>
          <p>You'd be eager to work on your game every day.</p>
          <p>
            You’d ship your game with confidence and momentum, and you’d be{" "}
            <em>proud</em> to show your <strong>finished</strong> game to your
            friends.
          </p>
          <p>But... how?</p>
        </Section>
        <Section>
          <Heading is="h1">
            Learn how to make games by example, with a{" "}
            <em>Game Codebase Tour</em> 🦮
          </Heading>
        </Section>
        <div
          style={{
            maxWidth: 900,
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: rhythm(1 / 2),
            paddingRight: rhythm(1 / 2),
            marginBottom: rhythm(1),
          }}
        >
          <Image
            fluid={data.gameCodebaseTour.childImageSharp.fluid}
            alt="Tangela"
          />
        </div>
        <Section>
          <p>You need an example. A role model.</p>
          <p>
            An actual, <em>finished</em> game project whose codebase (and
            process) you can study and reference.
          </p>
          <p>
            A <strong>Game Codebase Tour</strong> is exactly that. It's a{" "}
            <em>guided codebase</em> that:
          </p>
          <ol>
            <li>
              implements a game genre (match-3, roguelike, deckbuilder, etc.),
              and
            </li>
            <li>
              walks you through the game's codebase with{" "}
              <em>written feature guides</em>.
            </li>
          </ol>
          <p>With a Game Codebase Tour, you can...</p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            1. Learn how to make a similar game, by having a finished project to
            reference.{" "}
            <span role="img" aria-label="reference">
              📖
            </span>
          </Heading>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.codebase.childImageSharp.fluid}
              alt="codebase screenshot"
            />
          </div>
          <p>
            Want to build a match-3 game? A roguelike? A roguelike{" "}
            <em>deckbuilder</em> 😁?
          </p>
          <p>
            A Game Codebase Tour gives you full access to the clean,
            well-documented codebase of a <strong>production-quality</strong>{" "}
            game project.
          </p>
          <p>
            Use it as a reference implementation for your own games – 
            <em>especially</em> if you're building a game in the same genre.
          </p>
          <p>But when source code isn't enough...</p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            2. Learn how to scope your ideas, and how to turn ideas into code.{" "}
            <span role="img" aria-label="scope">
              🔎
            </span>
          </Heading>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.guideExample.childImageSharp.fluid}
              alt="Tangela"
            />
          </div>
          <p>
            Study the <strong>feature guides</strong> that accompany the source
            code.
          </p>
          <p>Each feature guide walks through:</p>
          <ol>
            <li>The original idea</li>
            <li>How to scope the idea, and how to scope down if needed</li>
            <li>How to translate the scope into bite-sized coding tasks</li>
            <li>
              Pointers to the relevant parts of the codebase, and how the code
              works
            </li>
            <li>Why the code works that way, and</li>
            <li>Exercises for testing your understanding.</li>
          </ol>
          <p>And not only that. You also get to...</p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            3. Learn how to write maintainable code.{" "}
            <span role="img" aria-label="maintainable">
              🏡
            </span>
          </Heading>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.cardPileSystem.childImageSharp.fluid}
              alt="Tangela"
            />
          </div>
          <p>
            Each game in a Game Codebase Tour is built as a set of{" "}
            <em>interacting game systems</em> that follow the{" "}
            <strong>MVP (Model-View-Presenter) design pattern</strong>.
          </p>
          <p>
            As such, a Game Codebase Tour teaches you how to write modular code
            that you can easily extend.
          </p>
          <p>
            Learn how to write clean code from the <em>start</em> of your
            project, so that adding features to your game is a{" "}
            <strong>joy</strong> – not a chore.
          </p>
          <div style={{ paddingBottom: rhythm(2) }} />
        </Section>
        <HighlightedBackground>
          <Section>
            <Heading is="h1">
              Why not take a tour?{" "}
              <span role="img" aria-label="eyes">
                👀
              </span>
            </Heading>
            {/*
            <p>
              The first tour available for sale is{" "}
              <strong>Game Codebase Tour: Match-3</strong>.
            </p>
            <p>You can purchase the tour below:</p>
            <iframe
              frameBorder="0"
              title="call to action 2"
              src="https://itch.io/embed/1111372"
              width="100%"
              height="167"
            >
              <a href="https://nucleartide.itch.io/pocket-pong-ar">
                Pocket Pong AR by nucleartide
              </a>
            </iframe>
            */}
            <EmailSignup
              altColor
              urlPath="/game-codebase-tours"
              copy={
                <>
                  <span>
                    Interested in learning how to make a match-3 game? A
                    roguelike? A roguelike <em>deckbuilder</em>?
                  </span>
                  <br />
                  <br />
                  <span>
                    I'm currently working on launching the first tour,{" "}
                    <strong>Game Codebase Tour: Match-3</strong>, and plan on
                    building more tours in the future.
                  </span>
                  <br />
                  <br />
                  <span>
                    Sign up for email updates as future tours make their way to
                    launch!
                  </span>
                </>
              }
            />

            <div style={{ paddingBottom: rhythm(1) }} />
          </Section>
        </HighlightedBackground>
        <Section>
          <Heading is="h1">
            Frequently Asked Questions{" "}
            <span role="img" aria-label="thinking">
              🤔
            </span>
          </Heading>
          <Heading is="h3" textAlign="left">
            Who makes these codebase tours, and why should I trust you?
          </Heading>
          <p>
            <em>Game Codebase Tours</em> is made by me,{" "}
            <a
              href="https://twitter.com/nucleartide"
              rel="noreferrer"
              target="_blank"
            >
              Jason Tu
            </a>
            .
          </p>
          <p>
            I work as a full-stack software engineer by day. And by night, I
            build game projects in Unity.
          </p>
          <p>
            This entire project is an effort to make game-making easier for
            folks like me. There's little guidance for hobbyist Unity developers
            on what a finished game looks like, and I aim to fix that by
            showcasing polished, well-thought-out examples.
          </p>
          <p>
            If you'd like to see my past content, you can check out my blog:{" "}
            <a href="https://jasont.co/" target="_blank" rel="noreferrer">
              jasont.co
            </a>
          </p>
          <Heading is="h3" textAlign="left">
            How well do I need to know Unity to follow these tours?
          </Heading>
          <p>You should have an intermediate, working knowledge of Unity:</p>
          <ul>
            <li>how to navigate the interface</li>
            <li>how to write scripts</li>
            <li>how to build simple games</li>
          </ul>
          <p>
            That said, if you are completely new to Unity, you'll still benefit
            by seeing how a complete game is put together.
          </p>
          <p>
            It all depends on how comfortable you are with diving into the deep
            end!
          </p>
          <Heading is="h3" textAlign="left">
            What if I go through a Game Codebase Tour, and have remaining
            questions?
          </Heading>
          <p>
            Reach out on the tour’s GitHub issue tracker (which will be linked
            as part of the purchased tour package), and I’ll respond as soon as
            I can.
          </p>
          <Heading is="h3" textAlign="left">
            What files are included in a Game Codebase Tour?
          </Heading>
          <p>You get the full, documented source code of a completed game.</p>
          <p>You also get access to a source code walkthrough.</p>
          <p>
            As of this writing, the source code walkthrough will be in the form
            of a written guide. However, I’m considering whether using Unity’s{" "}
            <a
              href="https://blog.unity.com/technology/in-editor-tutorials-available-to-try-in-unity-20211"
              rel="noreferrer"
              target="_blank"
            >
              In-Editor Tutorial Framework
            </a>{" "}
            might be a better option.
          </p>
          <Heading is="h3" textAlign="left">
            Will a Game Codebase Tour be paid?
          </Heading>
          <p>Yes, it will be paid.</p>
          <p>
            I’d like for these tours to solve the problem that plagues all
            part-time game developers:
          </p>
          <p>
            <em>
              “How is a complete game put together, and how can I do the same?”
            </em>
          </p>
          <p>
            Paying a small sum of money puts some skin into the game (so to
            speak), and helps both the buyer (and myself) take the project more
            seriously.
          </p>
          <Heading is="h3" textAlign="left">
            What's the license? Can I reuse the assets in my own projects?
          </Heading>
          <p>
            You can read a copy of the license{" "}
            <a
              href="https://gist.github.com/nucleartide/d550b567e59d4d0fb74f6bf2665db925"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            <strong>TL;DR</strong>: Do whatever you want with the source code
            and assets, including using them in open-source projects. But don’t
            resell them, or pass them off as yours on the Unity Asset
            Store. That’s not cool!
          </p>
          <p>
            Note that the license doesn't apply to the source code walkthrough.
          </p>
          <p>
            You should treat the source code walkthrough like owning a book: you
            can freely consume the book upon purchase, but you may not claim the
            book contents as your own.
          </p>
        </Section>
      </main>
      <Footer />
    </>
  )
}
